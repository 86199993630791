<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model :model="form" :colon="false" layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input placeholder="合同编号" v-model="form.code" style="width: 150px" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input placeholder="合同名称" v-model="form.name" style="width: 150px" />
            </a-form-model-item>

            <a-form-model-item>
              <a-select placeholder="状态" v-model="form.status" style="width: 150px">
                <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button v-if="$getPermission($route.path + '/add')" @click="$router.push($route.path + '/add')"
                type="primary">发起审批</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
                      total,
                      current,
                      pageSize,
                      showTotal: (total) => `共 ${total} 条记录`,
                    }">
        <a-table-column title="合同编号" data-index="code" width="120px" fixed="left">
        </a-table-column>
        <a-table-column title="合同名称" data-index="name"> </a-table-column>

        <a-table-column title="所属区域">
          <template slot-scope="text">
            <span>
              {{ text.areaName }}
            </span>
            <span v-if="text.subAreaName"> /{{ text.subAreaName }} </span>
            <span v-if="text.l3AreaName"> /{{ text.l3AreaName }} </span>
          </template>
        </a-table-column>
        <a-table-column title="合同方" data-index="partyAname">
        </a-table-column>

        <a-table-column title="是否有金额或费用比例" align="center">
          <template slot-scope="text">
            <span v-if="text.isKjContractExpense === 1">是</span>
            <span v-if="text.isKjContractExpense === 0">否</span>
          </template>
        </a-table-column>

        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="contractRegisterStatus" :dictValue="text.status" iconType="badge" />
          </template>
        </a-table-column>

        <a-table-column align="right" title="操作" width="160px" fixed="right">
          <template slot-scope="text">
            <a-space>
              <!-- 已通过 才可以签章 -->
              <a href="#" v-if="
                                              $getPermission($route.path + '/sign') &&
                                              text.status === 'approved'
                                            " @click.prevent="signText(text)">签章</a>
              <!-- 签订中 才可以归档 -->
              <a href="#" v-if="
                                              $getPermission($route.path + '/sign') &&
                                              text.status === 'signing'
                                            " @click.prevent="fileText(text)">归档</a>

              <a href="#" v-if="$getPermission($route.path + '/sign')" @click.prevent="
                                              $router.push($route.path + '/detail?id=' + text.id)
                                            ">详情</a>

              <a href="#" v-if="
                                              $getPermission($route.path + '/delete') &&
                                              text.status !== 'cancelled'
                                            " class="danger" @click.prevent="cancelText(text)">作废</a>
              <a href="#" v-if="$getPermission($route.path + '/delete')" @click.prevent="deleteText(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import {
  fetchList,
  remove,
  invalid,
  signature,
} from "@/api/framework-contract-register";
import { mapGetters } from "vuex";

export default {
  name: "framework-contract-register",
  mixins: [watermark],

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statusList() {
      return this.findDataDict("contractRegisterStatus");
    },
  },

  activated() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    signText(text) {
      const that = this;
      this.$confirm({
        title: "确认要签章吗？",
        onOk() {
          signature({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    fileText(text) {
      this.$router.push(this.$route.path + "/sign?id=" + text.id);
    },

    cancelText(text) {
      const that = this;
      this.$confirm({
        title: "确认要作废吗？",
        onOk() {
          invalid({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
    deleteText(text) {
      console.log(text);
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>