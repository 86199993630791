var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form-model',{attrs:{"model":_vm.form,"colon":false,"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"合同编号"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"合同名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"状态"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.statusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"right",staticStyle:{"margin-top":"4px"}},[(_vm.$getPermission(_vm.$route.path + '/add'))?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.$route.path + '/add')}}},[_vm._v("发起审批")]):_vm._e()],1)])],1)],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
                    total: _vm.total,
                    current: _vm.current,
                    pageSize: _vm.pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"合同编号","data-index":"code","width":"120px","fixed":"left"}}),_c('a-table-column',{attrs:{"title":"合同名称","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"所属区域"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',[_vm._v(" "+_vm._s(text.areaName)+" ")]),(text.subAreaName)?_c('span',[_vm._v(" /"+_vm._s(text.subAreaName)+" ")]):_vm._e(),(text.l3AreaName)?_c('span',[_vm._v(" /"+_vm._s(text.l3AreaName)+" ")]):_vm._e()]}}])}),_c('a-table-column',{attrs:{"title":"合同方","data-index":"partyAname"}}),_c('a-table-column',{attrs:{"title":"是否有金额或费用比例","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(text.isKjContractExpense === 1)?_c('span',[_vm._v("是")]):_vm._e(),(text.isKjContractExpense === 0)?_c('span',[_vm._v("否")]):_vm._e()]}}])}),_c('a-table-column',{attrs:{"title":"状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"contractRegisterStatus","dictValue":text.status,"iconType":"badge"}})]}}])}),_c('a-table-column',{attrs:{"align":"right","title":"操作","width":"160px","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[(
                                            _vm.$getPermission(_vm.$route.path + '/sign') &&
                                            text.status === 'approved'
                                          )?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.signText(text)}}},[_vm._v("签章")]):_vm._e(),(
                                            _vm.$getPermission(_vm.$route.path + '/sign') &&
                                            text.status === 'signing'
                                          )?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.fileText(text)}}},[_vm._v("归档")]):_vm._e(),(_vm.$getPermission(_vm.$route.path + '/sign'))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(_vm.$route.path + '/detail?id=' + text.id)}}},[_vm._v("详情")]):_vm._e(),(
                                            _vm.$getPermission(_vm.$route.path + '/delete') &&
                                            text.status !== 'cancelled'
                                          )?_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelText(text)}}},[_vm._v("作废")]):_vm._e(),(_vm.$getPermission(_vm.$route.path + '/delete'))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteText(text)}}},[_vm._v("删除")]):_vm._e()])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }